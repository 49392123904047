@use '../custom' as *;

.premilinary-offer-start {
  .waste-main {
    padding: 0px 0px 13px 0px;
    border-radius: 1.2rem;
    box-shadow: $box-shadow;
    background: $white;
    margin: 0 !important;

    @media only screen and (max-width: 1224px) {
      padding: 15px !important;
    }

    @media only screen and (max-width: 767px) {
      .premilinary-box {
        padding: 0 0 !important;
      }
    }

    .price {
      color: $admin-secondary;
    }

    .main-part-start {
      @media only screen and (max-width: 767px) {
        .location-border {
          width: auto !important;
        }
      }

      .input-add-location {
        border: 2px solid #efefef;
        border-radius: 0.5rem;
        box-shadow: $box-shadow;
        background: $white;

        .location-bg {
          background-color: #caf4f9;
          border: transparent;

          .location-ics {
            color: $admin-primary;
            font-size: 20px;
          }
        }

        .bg-transparent {
          background-color: transparent !important;
        }
      }
    }

    .main-bottom-valuation {
      box-shadow: $box-shadow;
      background: $white;

      @media only screen and (max-width: 767px) {
        display: block !important;
        text-align: center;
        margin: auto;
      }
    }

    .upload-document-text {
      color: $input-border-color !important;
    }

    .arrow-same-valuation {
      font-size: 1.3rem;
      border: $input-border-color;
      border-radius: 4px;
      padding: 8px 12px 8px 12px;
      cursor: pointer;
      color: $input-color;
    }

    .address-font {
      font-size: 18px;

      @media only screen and (max-width: 767px) {
        text-align: center;
      }
    }

    .arrow-valuation-different {
      color: $admin-secondary;
      border-color: $admin-secondary;
    }

    .change-color {
      background: $admin-tertiary;
      padding: 8px 20px;
      color: $white;
      text-decoration: none;
      font-weight: 400;
      border: none !important;
      font-size: 20px;
      box-shadow: 1px 8px 14px 0px rgb(2 206 230 / 31%);
      -webkit-appearance: none;

      &:hover {
        background: $admin-primary;
      }

      @media only screen and (max-width: 1224px) {
        margin-top: 10px;
      }

      @media only screen and (max-width: 767px) {
        text-align: center;
        margin-left: auto;
        margin-right: auto !important;
        margin-top: 10px;
        display: block;
      }
    }

    .btn-section {
      .btn-dark {
        color: $white;
        box-shadow: $box-shadow;
        font-size: 12px !important;
        -webkit-appearance: none;

        @media only screen and (max-width: 767px) {
          margin: 3px 0 8px 4px;
          font-size: 14px !important;
        }

        @media only screen and (max-width: 576px) {
          font-size: 10px !important;
        }
      }
      .btn-info {
        font-size: 12px !important;
      }
      @media only screen and (max-width: 767px) {
        margin: 3px 0 8px 4px;
        font-size: 14px !important;
      }

      @media only screen and (max-width: 576px) {
        font-size: 8px !important;
      }
    }

    .base-offer {
      color: $admin-primary;
    }

    .repair-charge {
      color: $admin-secondary;
    }

    .service-charge {
      color: $admin-tertiary;
    }

    .offer-details {
      h6 {
        font-size: 18px;
      }

      .price-adding {
        color: $admin-primary;
      }
    }

    @media only screen and (max-width: 767px) {
      .center-add {
        text-align: center;
      }

      .arrow-end {
        text-align: center;
        margin-top: 10px;
      }

      .blocking-add {
        display: block !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
      }

      .offer-details {
        margin-right: 0px !important;
        padding: 13px;
      }

      .paid-count {
        margin: 10px;
      }
    }

    .main-indicator-start {
      .events {
        width: 100%;
        display: block;

        .different-color {
          .circle {
            border: 5px solid $admin-tertiary;
            background: #f7ffe5;

            i {
              color: $admin-tertiary;
            }
          }

          .box-common {
            border: 4px solid #f3ffdb;
            background: $admin-tertiary;
          }
        }

        @media only screen and (max-width: 767px) {
          margin-left: 0px;
          padding: 0 0.8rem;
        }

        li {
          display: flex;
          color: $input-border-color;
          position: relative;
        }

        .success-box {
          width: 100%;
          margin-bottom: 113px;

          .circle-icon {
            color: #acd63f;
          }
        }

        .circle {
          height: 50px;
          width: 55px;
          border-radius: 50%;
          background: #f7f7f7;
          border: 5px solid $form-select-indicator-color;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;

          @media only screen and (max-width: 1224px) {
            height: 45px;
          }

          svg {
            width: 26px;
            height: 26px;
            color: $input-color;

            @media only screen and (max-width: 767px) {
              font-size: 21px;
            }
          }
        }

        .box-common {
          padding: 13px 18px 18px 18px;
          position: relative;
          width: 100%;
          background: $form-select-indicator-color;
          margin: 0 8px 0px 18px;
          border-radius: 10px;
          border: 4px solid $form-select-border-color;

          @media only screen and (max-width: 767px) {
            padding: 13px 8px 18px 8px;
            margin: 0 0px 0px 8px;
          }

          h5 {
            color: $white;
            font-weight: 800;

            @media only screen and (max-width: 1224px) {
              font-size: 14px;
            }

            @media only screen and (max-width: 767px) {
              font-size: 15px;
              word-break: break-word;
            }
          }

          span {
            color: $white;
            word-break: break-word;

            @media only screen and (max-width: 767px) {
              font-size: 13px;
            }
          }

          p {
            color: $white;

            @media only screen and (max-width: 767px) {
              font-size: 11px;
            }
          }

          .document-files {
            .document-btn {
              color: $white !important;
              text-transform: uppercase;
              border: transparent;
              background: transparent;
              -webkit-appearance: none !important;
            }
          }
        }

        strong {
          display: block;
          font-weight: bolder;
        }

        .all-dash-border {
          &:before {
            content: '';
            position: absolute;
            z-index: 1;
            left: 0;
            height: 100%;
            border-left: 2px $admin-secondary dashed;
            margin: 0 0 0 26px;

            @media only screen and (max-width: 767px) {
              margin: 0 0 0 22px;
            }
          }
        }

        .timezone {
          position: absolute;
          bottom: 0;
          right: 0;
          margin: 0px 23px 85px 0px;
          font-size: 17px;

          @media only screen and (max-width: 767px) {
            font-size: 14px;
          }
        }
      }
    }

    .main-heading {
      color: $admin-primary;
      font-size: 67px !important;
      font-weight: 700 !important;

      @media only screen and (max-width: 767px) {
        font-size: 32px !important;
        text-align: center;
      }
    }

    .mix-heading {
      font-size: 32px;
      letter-spacing: 1px;
      font-weight: 700;
      color: $admin-primary;

      @media only screen and (max-width: 767px) {
        font-size: 18px;
      }
    }

    .description-heading {
      margin: 0px 0 0 0;
      color: $admin-secondary;
      font-size: 27px;
      font-weight: 500;

      @media only screen and (max-width: 767px) {
        font-size: 18px;
        text-align: center;
      }
    }

    .form-label {
      color: $input-color;
    }

    .dots {
      .same-dot {
        font-size: 0.8rem;
      }
    }
  }

  .without-login {
    h2 {
      font-size: 50px;
    }

    p {
      font-size: 23px;
    }
  }
}

.valuation_list_class {
  padding: 2px 20px;
  cursor: pointer;
}

.location-border,
.valuation_list_class,
.valuation_list_class_input {
  cursor: pointer;
}

.diferent-color {
  svg {
    color: $admin-tertiary !important;
  }
}

.calendar-days {
  position: absolute;
  right: 0;
  margin: -48px 30px 0px 0px;
  cursor: pointer;
}

.upload-document-add {
  h5 {
    color: $input-color;
    font-size: 25px;
    font-weight: 800;
  }

  .input-document {
    border: 1.5px solid $input-color;
    border-radius: 10px;
  }

  .upload-close-btn {
    margin: initial !important;
    -webkit-appearance: none !important;
  }

  .upload {
    &__inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    &__btn {
      display: inline-block;
      font-weight: 600;
      text-align: center;
      padding: 40px 8px 40px 8px;
      transition: all 0.3s ease;
      cursor: pointer;
      border: 2px dashed $input-color;
      border-radius: 10px;
      width: 100%;
      -webkit-appearance: none !important;

      &-box {
        margin-bottom: 10px;
      }
    }
  }
}

.btn-rounded {
  background: $admin-primary;
  padding: 8px 20px;
  color: $white;
  text-decoration: none;
  font-weight: 400;
  border: none !important;
  font-size: 20px;
  box-shadow: 1px 8px 14px 0px rgb(2 206 230 / 31%);
  display: block;
  margin: 0 auto;
  -webkit-appearance: none !important;
}

.rouded-cancel-bt {
  background: transparent !important;
  color: #181818 !important;
  border: 1px solid #181818 !important;
  box-shadow: 1px 8px 14px 0px rgb(0 0 0 / 15%);
  line-height: 25px;
}

.chart-col-class .donut-chart {
  width: auto !important;
  height: 216px !important;
  //   margin-bottom: 12px;
}

.admin-question {
  border-bottom: 2px solid #181818;
  padding-bottom: 10px;
}

@media only screen and (max-width: 1224px) {
  .light-weighted-btn {
    margin-left: auto;
    display: block !important;
  }
}

@media only screen and (max-width: 767px) {
  .right-set-portion {
    text-align: center !important;
  }

  .right-set-portion-less {
    margin-right: 0px !important;
  }

  .spacing-add {
    margin: 4px 0 3px 0 !important;
  }

  .light-weighted-btn {
    display: initial !important;
  }
}

.filter-field-space .react-datepicker-wrapper .form-control {
  color: #181818;
}
