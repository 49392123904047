@use '../custom' as *;

.accordion-button {
  color: $primary !important;
  background-color: #fff !important;
  box-shadow: none;
  font-weight: 600;
}
.accordion-button:not(.collapsed) {
  color: $primary !important;
  box-shadow: none;
  font-weight: 600;
}
.accordion-button:focus {
  box-shadow: none !important;
}
