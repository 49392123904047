.demo-app-calendar .react-calendar {
  width: auto;
  border: none;
  box-shadow: 2rem;
}

.demo-app-calendar .react-calendar__tile--now {
  border-radius: 50%;
}

.demo-app-calendar button.react-calendar__tile--active,
.demo-app-calendar button.react-calendar__tile--active:enabled,
.demo-app-calendar button.react-calendar__tile--active:enabled:hover {
  background: #02cee6;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  max-width: 70px;
  max-height: 70px;
  font-size: 18px;
  font-weight: 800;

  @media only screen and (max-width: 767px) {
    width: auto;
    height: auto;
  }
}

.demo-app-calendar .react-calendar__tile,
.demo-app-calendar .react-calendar__month-view__weekdays__weekday {
  width: 60px;
  height: 60px;
  max-width: 98px;
  max-height: 70px;
  font-size: 17px;
  font-weight: 600;

  @media only screen and (max-width: 767px) {
    width: auto;
    height: auto;
  }
}

.demo-app-calendar .react-calendar * {
  justify-content: center;
  // display: flex;
}

.time-slot {
  border: 1.5px solid #96989a;
  border-radius: 0.5rem;
  font-size: 1.2rem;
}

.appointment-description,
.appointment-description:focus {
  border: transparent;
  border-bottom: 1px solid #96989a;
  padding-left: 0px;
  color: #181818;
  width: 100%;
  border-left-style: inset;
  background-color: transparent;
  border-left-style: inherit;
  padding: 0.5em;
}

.available-slot .slot_radio_btn {
  display: flex;
  align-items: center;
  margin: auto;
}

.available-slot .slot_radio_btn input[type='radio'] {
  // border: 0px;
  // width: 100%;
  // height: 1.1rem;
  width: 22px;
  height: 20px;
}

.add_not_section h5 {
  font-weight: bold;
}

.no-slot-available {
  display: flex;
  align-items: center;
  margin: auto;
  height: 85%;
  border-radius: 1.2rem;
  box-shadow: 1px -1px 14px 0px rgb(0 0 0 / 12%);
  background: $white;

  @media only screen and (max-width: 1224px) {
    height: auto;
  }

  h5 {
    font-size: 21px;
    font-weight: 700;
  }
}

.disabled_arrow_btn {
  color: #96989a;
}
