@import '../custom';


.time-label {
    padding: 0px;
}

.working-text-center {
    padding-top: 0.63rem;
}

.secondary-add-button {
    height: 39px
}

.date-form-control {
    width: 55%;
    margin-left: 25px;
    border-radius: 0.5rem;
    padding: 0em 0.4em;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    @media only screen and (max-width: 767px) {
        width: 94% !important;
    }
}

.secondary-remove-button {
    height: 39px
}