@use '../custom' as *;

.chat-wrapeer {
  // margin: 60px 0px 30px 0px !important;

  @media only screen and (max-width: 991px) {
    padding-top: 13px !important;
  }

  .main-section {
    margin: 13px auto 0px auto;

    .body-section {
      clear: both;
      width: 100%;

      .left-section {
        background: #ffffff;
        border-radius: 18px;
        box-shadow: 1px -1px 14px 0 rgb(0 0 0 / 12%);
        height: 83vh;

        .headLeft-sub {
          padding: 15px;
          text-align: center;

          h4 {
            color: #181818;
          }

          .search_btn {
            top: 5px;
            left: 7px;
            background-color: transparent !important;
            border: 0 !important;
            color: #cdcdcd !important;
            cursor: pointer;
            position: absolute;
            z-index: 5;
            font-size: 18px;
            -webkit-appearance: none !important;
          }

          input.form-control.search {
            padding-left: 48px !important;
            padding: 7px;
            border-radius: 50px !important;
            border: 1px solid #96989a;
          }
        }

        .user_list {
          z-index: 1;
          position: relative;
          height: 70vh !important;
          overflow-y: auto;

          ul {
            padding: 0px;
            margin: 0px;
            list-style: none;

            li {
              padding: 20px 13px;
              cursor: pointer;
              border-bottom: 1px solid #ddd;

              .chatList {
                overflow: hidden;

                .img {
                  width: 60px;
                  float: left;
                  text-align: center;
                  position: relative;

                  img {
                    height: 48px;
                    width: 48px;
                    object-fit: cover;
                  }
                }

                .desc {
                  width: calc(100% - 73px);
                  float: left;
                  position: relative;

                  .chat_unread_count {
                    position: absolute;
                    right: 15px;
                    background-color: #00bfdb;
                    border-radius: 50%;
                    height: 1.7rem;
                    width: 1.7rem;
                    align-items: center;
                    font-size: 12px;
                    display: flex;
                    justify-content: center;
                    color: white;
                    font-weight: 700;

                    @media only screen and (max-width: 1224px) {
                      right: 0;
                      height: 1.5rem;
                      width: 1.5rem;
                      font-size: 11px;
                    }
                  }

                  .time {
                    position: absolute;
                    right: 0px;
                    color: #96989a;
                    font-size: 12px;
                  }

                  small {
                    margin: 30px 0 0 0;

                    @media only screen and (max-width: 1224px) {
                      margin: 3px 0 0 0;
                      float: left;
                      top: 28px;
                      font-size: 11px;
                    }
                  }

                  h5 {
                    margin-top: 13px;
                    line-height: 5px;
                    font-size: 18px;
                    color: #181818;
                    margin-bottom: 11px;

                    @media only screen and (max-width: 1224px) {
                      text-align: left;
                      font-size: 11px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .right-section {
        padding: 0px 0px 13px 0px;
        height: 83vh;
        background: #ffffff;
        border-radius: 18px;
        box-shadow: 1px -1px 14px 0 rgb(0 0 0 / 12%);

        .headRight-sub {
          padding: 10px 0px 11px 13px;
          background: #ffffff;
          box-shadow: 1px -1px 14px 0px rgb(0 0 0 / 12%);
          border-radius: 18px 18px 0px 0px;

          .img_cont {
            position: relative;

            .user_img {
              height: 48px;
              width: 48px;
              object-fit: cover;
            }
          }

          .user_info {
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 15px;
            width: 100%;

            a {
              color: #181818;
              text-decoration: none;
            }
          }
        }

        .message {
          height: calc(100% - 128px);
          border-bottom-width: 0px !important;
          margin: 0 0 3px 0;

          .sidebar-scroll {
            overflow-y: auto;
            height: 100%;
            width: 100%;
          }

          ul {
            padding: 12px;
            list-style: none;
            margin: 0px auto;
            width: 100%;
            height: auto;
            overflow-y: hidden;

            li.msg-left {
              position: relative;
              width: 50%;
              padding: 16px 0;
              clear: both;
              word-break: break-word;
              padding-left: 13px;
              padding-right: 0px;

              @media only screen and (max-width: 1224px) {
                width: 100%;
              }

              .user_img {
                height: 48px;
                width: 48px;
                object-fit: cover;
                float: left;
                position: absolute;
              }

              .msg-desc {
                margin-left: 57px;
                font-size: 16px;
                background: #f5f5f5;
                padding: 10px;
                position: relative;
                color: #181818;
                margin-top: 10px;
                text-overflow: initial;
                overflow: hidden;
                display: inline-block;
                // float: left;
                text-align: left;
                border-radius: 16px 16px 16px 0px;

                @media only screen and (max-width: 1224px) {
                  margin-right: 65px;
                  font-size: 14px;
                }

                .img-thumbnail {
                  width: 100%;
                  background: transparent;
                  border-color: transparent;
                  margin-bottom: 10px;
                }

                span.msg_time_send {
                  margin-right: 20px;
                  color: #96989a;
                  right: 10px;
                  position: absolute;
                  white-space: nowrap;
                  float: right;
                  margin-top: 10px;
                  bottom: -1px !important;
                  font-size: 12px;
                }
              }
            }

            li.msg-right {
              float: right;
              text-align: right !important;
              position: relative;
              width: 50%;
              padding: 16px 0;
              clear: both;
              word-break: break-word;
              padding-left: 41px;
              padding-right: 12px;

              @media only screen and (max-width: 1224px) {
                width: 100%;
              }

              .msg-left-sub {
                display: grid;
                justify-content: flex-end;
              }

              .user_img {
                text-align: right;
                float: right;
                height: 48px;
                width: 48px;
                object-fit: cover;
              }

              .msg-desc {
                font-size: 16px;
                background: #15d1e7;
                color: #ffffff;
                padding: 10px;
                border-radius: 16px 16px 0px 16px;
                position: relative;
                text-align: left;
                text-overflow: initial;
                overflow: hidden;
                margin-top: 10px;
                margin-left: 35px;
                width: fit-content;
                margin-right: 15px;

                @media only screen and (max-width: 1224px) {
                  // margin-left: 61px;
                  font-size: 14px;
                }

                span.msg_time_send {
                  margin-right: 20px;
                  color: #96989a;
                  position: inherit;
                  white-space: nowrap;
                  float: right;
                  margin-top: 10px;
                  bottom: -1px !important;
                  font-size: 12px;
                }

                .img-thumbnail {
                  width: 100%;
                  background: transparent;
                  border-color: transparent;
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }

      .maintext-area {
        padding: 10px 0px 13px 13px;
        background: #ffffff;
        box-shadow: 1px -1px 14px 0px rgb(0 0 0 / 12%);
        border-radius: 0px 0px 18px 18px;
        display: flex;
        align-items: center;

        textarea {
          resize: none;
          width: 76%;
          height: 46px;
          font-size: 17px;
          padding: 9px 18px 9px 18px;
          border: 1px solid #96989a;
          color: #181818;
          border-radius: 24px;

          @media only screen and (max-width: 767px) {
            width: 42% !important;
          }

          @media only screen and (max-width: 991px) {
            width: 52%;
          }
        }

        .upload-img {
          svg {
            font-size: 24px;
            color: #96989a;
            margin: 0 8px 0 8px;
          }
        }

        .send-button {
          button {
            background: #02cee6;
            padding: 8px 20px;
            color: #ffffff;
            text-decoration: none;
            font-weight: 400;
            border: 0 solid hsla(0, 0%, 100%, 0.6);
            font-size: 20px;
            box-shadow: 1px 8px 14px 0 rgb(2 206 230 / 31%);
          }
        }
      }
    }
  }
}

.small {
  margin: 0 0 0 59px;
}

.small-right {
  margin: 0 15px 0 0px;
}

small {
  color: #96989a;
}

.bars_icons {
  display: none;
}

@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  .msg-right {
    .msg-desc {
      margin-left: 213px !important;
    }
  }

  .msg-left {
    .msg-desc {
      margin-right: 213px !important;
    }
  }

  textarea {
    width: 74% !important;
  }
}

@media only screen and (min-device-width: 412px) and (max-device-height: 915px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3.5) {
  .msg-right {
    .msg-desc {
      margin-left: 213px !important;
    }
  }

  .msg-left {
    .msg-desc {
      margin-right: 213px !important;
    }
  }

  textarea {
    width: 74% !important;
  }
}

@media only screen and (min-device-width: 393px) and (max-device-height: 851px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2.75) {
  .msg-right {
    .msg-desc {
      margin-left: 213px !important;
    }
  }

  .msg-left {
    .msg-desc {
      margin-right: 213px !important;
    }
  }

  textarea {
    width: 74% !important;
  }
}

@media only screen and (min-device-width: 390px) and (max-device-height: 844px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  .msg-right {
    .msg-desc {
      margin-left: 213px !important;
    }
  }

  textarea {
    width: 74% !important;
  }
}

@media only screen and (max-width: 767px) {
  .bars_icons {
    display: block;
  }

  .desktop-section {
    transition: opacity 10s ease-out;
  }

  .sidebar-collaped {
    display: block;
    position: absolute;
    z-index: 1;
    width: 95%;
    visibility: visible;
    opacity: 1;
    transition: opacity 10s ease-out;
  }

  .sidebar-none {
    display: none;
  }

  #content-wrapper {
    min-height: fit-content !important;
  }

  .second-chat-modal {
    margin: 0px 0px 30px 0px !important;
  }
}

video {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.user-list-collaps {
  z-index: 2;
  position: fixed;
}

.download-icon {
  background-color: #a9d950;
  color: #ffffff;
  font-size: 1.2rem;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  text-align: center;
  font-weight: 700;
  padding: 5px;

  span {
    cursor: pointer;
  }
}

.chat-link-href {
  &:hover {
    color: #a9d950 !important;
  }
}

span.online-dot {
  padding: 0.5rem;
  position: absolute;
  top: 0;
  left: 70%;
  z-index: 0;
  background-color: green;
  border-radius: 50%;
  border: #ffffff 1px solid;
}