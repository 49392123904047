@use '../custom' as *;

.navbar-section {
  padding: 0px !important;

  .container-section {
    padding: 0px !important;
    background-color: #fff;
    box-shadow: 0 0.125rem 0.25rem rgb(66 66 66 / 8%) !important;
  }
}

.dashboard-logo {
  padding: 0px !important;
  width: 13.4rem;

  img {
    height: 50px;
    width: auto;
  }
}

.header-setting-menu {
  display: flex;
  margin: 0 8px 0 0px;

  .desktop-login-name {
    margin-right: 10px;
    margin-top: 7px;
    font-weight: 500;
    color: $tertiary;
  }

  .profile-img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
  }

  a::after {
    display: none;
  }

  .dropdown-menu {
    left: -150px !important;
    margin-top: 1.125rem !important;
    z-index: 99999;
  }

  .mobile-login-name {
    display: none;
  }

  .setting-items a {
    color: #424242;
    font-weight: 500;
    text-decoration: none;
  }

  .dashboard-toggle-btn {
    display: none;
  }

  .add-proper-cls {
    margin: 7px 0 0px 7px;
  }
}

.setting-items:active a {
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .header-setting-menu {
    margin-top: 5px;

    .desktop-login-name {
      margin-top: 10px;
    }

    .dashboard-toggle-btn {
      display: block;
      margin-top: 10px;
      margin-right: 15px;
      -webkit-appearance: none !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .header-setting-menu {
    .desktop-login-name {
      display: none;
    }

    .mobile-login-name {
      display: block;
      text-align: center;
    }

    .dashboard-toggle-btn {
      display: block;
      margin-top: 10px;
      margin-right: 15px;
      -webkit-appearance: none !important;
    }
  }
}

.notification {
  background-color: var(--ds-surface-overlay, #fff);
  border-radius: 3px;
  box-shadow: var(--ds-shadow-overlay, 0 7px 11px -2px #000, 0 0 1px rgba(0, 0, 0, 0.31));
  height: calc(100vh - 113px);
  margin: 37px 190px 0 0;
  overflow-y: auto;
  position: absolute;
  right: 38px;
  width: 43%;

  @media only screen and (max-width: 767px) {
    width: fit-content;
    right: 0px;
    margin: 37px 13px 0px 13px;
  }
}

.location-border {
  padding: 5px 13px 0px 13px;
}

.input-add-location {
  border: 2px solid #efefef;
  border-radius: 0.5rem;
  box-shadow: 1px -1px 14px 0px rgb(0 0 0 / 12%);
  background: #fff;
  justify-content: space-between !important;

  @media only screen and (max-width: 1224px) {
    justify-content: center !important;
  }
}

.notification-list {
  border-left: 6px solid #181818;
}

.notification-list:hover {
  border-left: 6px solid #02cee6 !important;
}

.sub-title-text {
  font-size: 13px;
}

svg.bi.bi-bell {
  cursor: pointer;
}

.align-items-center {
  height: 100%;
  justify-content: center;
}

@media only screen and (max-width: 1224px) {
  svg.bi.bi-bell {
    margin-top: 7px !important;
  }
}
.waste-main .main-part-start .location-border {
  width: auto !important;
}
.waste-main .main-part-start .input-add-location {
  border: 2px solid #efefef;
  border-radius: 0.5rem;
  margin-bottom: 13px;
  box-shadow: 1px -1px 14px 0px rgb(0 0 0 / 12%);
  background: #fff;
}
