$primary: #53bdde;
$secondary: #1176d4;
$tertiary: #424242;
$error: #d42a2a;
$focus: #1176d4;
$success: #28b012;
$primary-hover: #004e97;
$secondary-hover: #004e97;
$sub-heading: #aaaaaa;
$error-hover: #a10f0f;
$view-text: #636363;
$disabled-btn: #ebebeb;
$disabled-text: #a4a4a4;
$admin-primary: #02cee6;
$admin-secondary: #181818;
$admin-tertiary: #acd63f;
$white: #fff;
$box-shadow: 1px -1px 14px 0px rgba(black, 12%);
$input-border-color: 1px solid #96989a;
$form-select-indicator-color: #888888;
$form-select-border-color: #dedede;
$input-color: #96989a;

.auth-pages {
  padding-top: 70px;
  padding-bottom: 60px;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;

  form {
    width: 100%;
    margin: 0 auto;
    display: block;
    max-width: 324px;
  }
}

.current-list-table td:nth-child(5) {
  white-space: nowrap;
}

.background-box {
  background: #fff;
  padding-top: 30px !important;
  padding-bottom: 20px !important;
  max-width: 900px;
  margin: 0 auto;
}

.bg-box {
  background-color: #f0f5f1 !important;
}

.brand-logo {
  margin-top: 3rem;
  text-align: center;

  img {
    height: 90px;
    width: auto;
  }
}

.form-group-field.input-group svg {
  color: $sub-heading;
}

.normal-box {
  margin: 0 auto;
  width: 324px;
}

ol.breadcrumb {
  margin: 0px 0px 1.5rem 0px;

  li {
    font-weight: 500;
    color: $tertiary;

    a {
      color: $tertiary;

      &:hover {
        color: $primary;
      }
    }
  }
}

.showLoader {
  display: inherit !important;
}

.hideLoader {
  display: none !important;
}

.preview-image {
  height: 100px;
  width: 100px;
  position: relative;
  margin-top: 0.5rem;

  svg {
    margin-left: 1rem;
    color: $error;
    float: right;
    margin-right: -10px;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    -o-object-fit: contain;
    object-fit: contain;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef !important;
}

.alert-popup {
  width: 100%;
  padding: 2rem;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
}

.alert-box {
  margin: 10%;
}

.alert-box .alert-close {
  float: right;
  font-size: 25px;
  padding: 0.5rem 0.5rem;
  color: $error;
  cursor: pointer;
}

.table-secondary-button {
  width: max-content;
}

.row-top {
  margin-top: 1.5rem;
}

.newsecondary-add-button {
  margin: -9px 0 0 0 !important;
}

.rc-time-picker-panel {
  z-index: 999;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  min-width: fit-content;
}

.inner-box-details {
  margin: 0rem 6rem 1.5rem 6rem;
  color: $tertiary !important;
  font-weight: 500;

  span {
    color: $view-text;
  }

  .divider {
    width: 4%;
  }
}

.form-check-input:checked[type='radio'] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.react-confirm-alert-overlay {
  z-index: 100000;
}

.working-details {
  // display: inline-flex;
  display: block;
  color: #424242;
  font-weight: 500;
  margin-top: 0.5rem;

  .working-details-lable {
    padding: 0.5rem 0.5rem 0.5rem 2rem;
  }

  .working-details-hours {
    display: flex;
  }

  .working-list-hours {
    margin-left: 5.5rem;
  }

  .form-check {
    margin-top: 0.5rem;
  }
}

.working-details button.secondary-remove-button {
  margin: 0px;
}

@media screen and (max-device-width: 991px) and (orientation: landscape) {
  .auth-pages {
    height: auto;
  }
}

@media only screen and (max-width: 991px) {
  .overly .filter {
    display: block;
    float: initial;
    z-index: -1;
    position: relative;
  }

  .inner-box-details {
    .divider {
      width: 8.33333333%;
    }
  }

  .overly .table-responsive {
    z-index: -1;
    position: relative;
  }

  .overly .card {
    z-index: -1;
    position: relative;
  }

  .newsecondary-add-button {
    margin: 13px 0 0 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .background-box {
    padding: 0px 10%;
    width: 90%;
  }

  .inner-box-details {
    margin: 1rem;
  }

  .ck.ck-editor {
    width: 99% !important;
  }

  .form-group-field {
    width: 100% !important;
  }

  .normal-box {
    width: auto;
  }

  .overly .table-responsive {
    z-index: -1;
    position: relative;
  }

  .overly .filter {
    z-index: -1;
    position: relative;
  }

  .overly .card {
    z-index: -1;
    position: relative;
  }

  .alert-popup {
    width: 92%;
    margin-left: 4%;
  }

  .alert-box .alert-close {
    padding: 0.5rem 1.5rem;
  }

  .padding-up {
    margin-top: 8px !important;
  }

  .donut-chart-legend-item-label {
    font-size: 12px;
    -webkit-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
  }

  .working-details button.secondary-remove-button {
    margin: 13px 0px 0px 0px;
  }
}

@media only screen and (max-width: 1224px) {
  .working-details {
    .working-text-center {
      .main-top-add {
        margin-top: 22px !important;
      }
    }
  }

  .same-box-add {
    margin-bottom: 13px !important;
  }

  .brand-logo {
    img {
      height: fit-content !important;
      width: 100% !important;
    }
  }
}

.notification-counter {
  position: relative;
  width: 20px;
  top: -8px;
  left: 25px;
  padding: 1px;
  background-color: #01bfdc;
  height: 20px;
  border-radius: 50%;
  min-width: 20px;
  padding: 4px;
  font-size: 0.7rem;
}
