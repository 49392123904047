@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner-container {
  position: absolute;
  z-index: 5;
  background-color: rgb(0 0 0 / 30%);
  width: 100vw;
  height: 100vh;
}

.loading-spinner {
  left: 48%;
  top: 50%;
  position: absolute;
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #015a06;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
