@use './custom' as *;

@import './components/button';
@import './components/typography';
@import './section/header';
@import './section/sidebar';
@import './section/chat';
@import './section/premilinaryoffer';
@import './section/myvaluation';
@import './section/bookappointment';
@import './components/navlink';
@import './components/link';
@import './components/table';
@import './components/tab';
@import './components/input';
@import './components/accordion';
@import './section/dashboard';
@import './custom';

@import '../../../node_modules/bootstrap/scss/bootstrap.scss';

.demo-app-calendar {
  button.react-calendar__navigation__label {
    background: #02cee6;
    color: white;
    font-weight: 800 !important;
    font-size: 18px;
    border-radius: 8px;
    -webkit-appearance: none !important;

    &:hover {
      background-color: #181818 !important;
      color: white !important;
    }

    @media only screen and (max-width: 767px) {
      font-size: 12px !important;
    }

    @media only screen and (max-width: 1224px) {
      min-width: max-content !important;
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    font-size: 15px;

    @media only screen and (max-width: 767px) {
      font-size: 12px !important;
    }
  }

  abbr {
    text-decoration: none !important;
  }

  .react-calendar__navigation__prev2-button {
    font-size: 23px;
  }

  .react-calendar__navigation__next2-button {
    font-size: 23px;
  }

  .react-calendar__navigation__prev-button {
    font-size: 19px;
  }

  .react-calendar__navigation__next-button {
    font-size: 19px;
  }

  .react-calendar__month-view__days__day {
    &:hover {
      background-color: #181818 !important;
      color: white !important;
    }
  }

  .react-calendar__tile:disabled {
    &:hover {
      background-color: #a8a8a8 !important;
      color: white !important;
    }
  }
}

.hours-available {
  font-size: 18px !important;
  font-weight: 600;
  margin-bottom: 15px !important;
  word-break: break-word;
  color: #303030 !important;
}

.react-calendar__tile--now {
  background: #acd63f !important;
  color: #fff !important;
}

.bg-heading-add {
  font-size: 1.5rem !important;
  font-weight: 800;
  word-wrap: break-word;
}

.light-weighted-btn {
  -webkit-appearance: none !important;
}

.btn-light {
  border-radius: 6px !important;
  -webkit-appearance: none !important;
  font-size: 12px !important;
}

svg.svg-inline--fa.fa-xmark {
  position: absolute;
  margin-left: -18px;
  margin-top: -6px;
  font-size: 28px;
}

@media only screen and (max-width: 767px) {
  .right-side-added {
    margin-left: auto;
    text-align: end;
    margin-top: 7px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1224px) {
  .add-mt-md {
    margin-top: 8px;
  }

  .paid-count {
    .offer-details {
      h6 {
        font-size: 15px !important;
      }

      h4 {
        font-size: 17px !important;
      }
    }
  }
}

// valuation css media button
.buttons-wrap {
  .btn {
    @media only screen and (max-width: 576px) {
      font-size: 9px !important;
    }
  }
  // .btn-dark {
  //   @media only screen and (max-width: 576px) {
  //     font-size: 10px !important;
  //   }
  // }
}
