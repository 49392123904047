@import '../custom';

table thead {
  background-color: #f0f5f1;
  color: $primary;
  font-size: 18px;
  white-space: nowrap;

  th {
    font-weight: 600;
  }
}

table tbody {
  font-weight: 500;
  color: $tertiary;
}
.css-6j8wv5-Input {
  display: flex !important;
}
.tablepagination {
  text-align: center;
}
.overly .tablepagination {
  z-index: -1;
  position: relative;
}

.filter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.filter-field-space {
  margin: 1rem 0.5rem;
}

.filter-column {
  width: 100%;
  height: 45px;
}

.action_btn {
  display: inline-flex;
  -webkit-appearance: none !important;
}

.status-active {
  color: $primary;
  cursor: pointer;
}

.status-inactive {
  color: $error;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .filter {
    display: block;
    float: initial;
  }

  .filter button {
    float: right;
  }

  .filter-column {
    width: 100%;
  }
}
.css-6j8wv5-Input {
  display: flex !important;
}
