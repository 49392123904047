@use '../custom' as *;

.main-card-dashboard {
  box-shadow: 0 3px 18px 0 rgb(0 0 0 / 13%), 0 18px 25px 0 rgb(0 0 0 / 3%);
  padding: 30px 13px;
  border-radius: 16px;
  background: $white;
  position: relative;
  height: 100%;

  &:hover {
    background: #f3f3f3;
    padding: 30px 13px;
    border-radius: 16px;
    box-shadow: 0 3px 28px 0 rgb(0 0 0 / 13%), 0 13px 33px 0 rgb(0 0 0 / 8%);
  }

  h5 {
    color: #02cee6;
    font-weight: 800;
  }

  .page-link {
    color: #181818 !important;
    text-decoration: none;
    font-weight: 600;
    display: inline;
  }
}
